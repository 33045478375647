
import { mapGetters, mapActions } from 'vuex';
import { NewsModel } from '@/modules/news/news-model';

export default {
  name: 'app-auction-list-table',
  data() {
    return {
      value: '',
      value2: '',
      page: parseInt(this.$route.query.page) || 1,
      action: '',
    };
  },
  components: {},
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      is_labtop: 'layout/labtop',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_ipad_pro: 'layout/is_screen_ipad_pro',
      auctions: 'auction/list/auctions',
      mountedTable: 'news/list/mountedTable',
      loading: 'auction/list/loading',
      currentLanguageCode: 'layout/currentLanguageCode',
      pagination: 'auction/list/pagination',
    }),
    isRTL() {
      return this.$i18n.locale == 'ar';
    },
    paginationPage() {
      return this.page - 1 || 0;
    },
  },

  methods: {
    ...mapActions({
      doFetch: 'auction/list/doFetch',
    }),
    next() {
      this.page++;
      this.action = 'next';
    },
    prev() {
      this.page--;
      this.action = 'prev';
    },
    presenter(row, fieldName) {
      const val = NewsModel.presenter(row, fieldName);
      return val ? val : 'ـــــــ';
    },
    presenterTitle(row) {
      if (!this.isRTL) {
        const val = NewsModel.presenter(row, 'title_en');
        return val ? val['en'] : 'ـــــــ';
      } else {
        const val = NewsModel.presenter(row, 'title');
        return val ? val : 'ـــــــ';
      }
    },
    presenterDescription(row) {
      if (!this.isRTL) {
        const val = NewsModel.presenter(
          row,
          'description_en',
        );
        return val ? val['en'] : 'ـــــــ';
      } else {
        const val = NewsModel.presenter(row, 'description');
        return val ? val : 'ـــــــ';
      }
    },
  },
  watch: {
    async page() {
      this.$router.push({
        path: 'auctions',
        query: { page: this.page },
      });
      await this.doFetch({
        branchId: this.current,
        action: this.action,
        currentPage: this.paginationPage,
      });
    },
    '$route.params.page': {
      handler: function () {
        this.page = this.$route.query.page || 1;
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.doFetch({
      branchId: this.current,
      action: 'next',
      currentPage: this.paginationPage,
    });
  },
};
